import(/* webpackMode: "eager", webpackExports: ["FooterSimple"] */ "/home/runner/work/Prometheus.EDU/Prometheus.EDU/components/FooterSimple/FooterSimple.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/Prometheus.EDU/Prometheus.EDU/components/HeaderMenu/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/home/runner/work/Prometheus.EDU/Prometheus.EDU/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/home/runner/work/Prometheus.EDU/Prometheus.EDU/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/Prometheus.EDU/Prometheus.EDU/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/home/runner/work/Prometheus.EDU/Prometheus.EDU/theme.ts");
